<script setup lang="ts">
const { $analytics } = useNuxtApp();
const dialogManageCookies = ref();
const consent = useConsent();

const onAccept = () => {
	consent.value = {
		analytics: true,
		confirmed: true,
	};
	$analytics.setAnalyticsConsent(true);
};

const onReject = () => {
	consent.value = {
		analytics: false,
		confirmed: true,
	};
	$analytics.setAnalyticsConsent(false);
};
</script>

<template>
	<div class="cookie-notification" v-if="!consent.confirmed">
		<div class="cookie-notification__container">
			<div class="cookie-notification__start">
				<span class="cookie-notification__content editorial">
					{{ $t('cookies.notification1') }}
					<NuxtLink :to="{ name: 'app.privacyPolicy' }" class="link">
						<span>{{ $t('footer.privacyPolicy') }}</span>
					</NuxtLink>
					{{ $t('cookies.notification2') }}
				</span>
			</div>
			<div class="cookie-notification__end">
				<Button :label="$t('cookies.accept')" severity="primary" @click="onAccept" />
				<Button :label="$t('cookies.reject')" @click="onReject" />
				<Button :label="$t('cookies.manageCookies')" @click="dialogManageCookies.open()" />
			</div>
		</div>
	</div>

	<DialogManageCookies ref="dialogManageCookies" />
</template>
