<script setup lang="ts">
const { $analytics } = useNuxtApp();
const visible = ref(false);
const consent = useConsent();
const analytics = ref(consent.value.analytics);

const onSave = () => {
	consent.value = {
		analytics: analytics.value,
		confirmed: true,
	};
	visible.value = false;
	$analytics.setAnalyticsConsent(analytics.value);
};

const open = () => {
	analytics.value = useConsent().value.analytics;
	visible.value = true;
};
defineExpose({ open });
</script>

<template>
	<PvDialog
		modal
		:draggable="false"
		:show-header="false"
		v-model:visible="visible"
	>
		<div class="dialog">
			<DialogHeader :label="$t('cookies.manageCookies')" icon="half-cookie" @update:visible="visible = false" />
			<DialogBody>
				<DataContainer no-margin>
					<Data label="Required">
						<p class="fs:14">{{ $t('cookies.requiredDescription') }}</p>
					</Data>
					<Data :label="$t('props.analytics')">
						<p class="fs:14 mye:16">{{ $t('cookies.analyticsDescription') }}</p>
						<DataItem :label="$t('cookies.enable')">
							<template #end>
								<FormSwitch id="analytics" v-model="analytics" />
							</template>
						</DataItem>
					</Data>
				</DataContainer>
			</DialogBody>
			<DialogFooter>
				<Button
					:label="$t('cookies.saveChanges')"
					severity="primary"
					block
					@click="onSave"
				/>
				<Button :label="$t('actions.cancel')" block @click="visible = false" />
			</DialogFooter>
		</div>
	</PvDialog>
</template>
